import {
  useContext,
  createContext,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import queryString from 'query-string';
import { fabric } from 'fabric';
import { usePostHog } from 'posthog-js/react';
import Router, { useRouter } from 'next/router';
import _ from 'lodash';
import { convertDataURIToBuffer, isEmpty, useFeedback } from '@nex/labs';

import { useCreateOrUpdateSketchMutation } from '@/state/query/block';
import {
  useArtboardStore,
  useCanvasStore,
  useUserStore,
} from '@/state/useStore';

import { ActiveTool } from '@nex/types/sketch';
import { defaultNavElement, getDrawCursor } from '@/utils/canvas-lib/utils';
import { handleDelete } from '@/utils/canvas-lib/key-events';

import {
  useActiveImage,
  useFeatherPaint,
  useLasso,
  useCanvasSize,
  useCanvasHistory,
  useShapesMutation,
  useMagicSelect,
  useContainerZoom,
  /* -------------------------------- end hooks ------------------------------- */
  disableCanvasObjects,
  enableCanvasObjects,
  handleCanvasBackground,
  initializeCursor,
  initializeFabric,
  useKeyEvents,
} from '@/utils/canvas-lib';
import { FabricContext } from './context';

export function useFabric() {
  return useContext(FabricContext);
}

export const FabricProvider = ({
  children,
  initialize = false,
  isCanvas = false,
}: {
  children?: React.ReactNode;
  initialize?: boolean;
  isCanvas?: boolean;
}) => {
  const { createToast, createDisclosure } = useFeedback();

  const router = useRouter();
  const posthog = usePostHog();
  const { activeTab } = useArtboardStore();
  const {
    setActiveTool,
    activeTool,
    setBlock,
    setPopoverBounds: setBound,
  } = useCanvasStore();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { profile } = useUserStore();

  const { mutateAsync: createOrUpdateSketch, isLoading: isSaving } =
    useCreateOrUpdateSketchMutation();

  const fabricRef = useRef<fabric.Canvas | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isDrawingRef = useRef<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isEditingRef = useRef<boolean>(false);
  const shapeRef = useRef<fabric.Object | null>(null);
  const activeObjectRef = useRef<any>(null);
  const selectedShapeRef = useRef<{
    value?: unknown;
    attributes?: unknown;
  } | null>(null);

  const canvas = useMemo(
    () => fabricRef.current,
    [fabricRef.current]
  ) as fabric.Canvas;

  const { saveState, undo, redo, canRedo, canUndo, clearHistory } =
    useCanvasHistory({
      fabricRef,
    });

  const { zoom, resetView, zoomToPoint, panning } = useContainerZoom({
    containerRef,
    fabricRef,
  });

  const { canvasSize, forceSetCanvasSize, hasWrongCanvasSize, handleResize } =
    useCanvasSize({
      isCanvas,
      fabric: fabricRef,
    });

  const isSketch = useMemo(() => {
    if (typeof router?.query?.sketchId === 'string') {
      return true;
    }

    if (activeTab === 'sketch' && typeof router?.query?.sketchId === 'string') {
      return true;
    }

    return false;
  }, [router?.query?.sketchId, activeTab]);

  useEffect(() => {
    if (!initialize || !canvasRef.current) return;

    const canvas = initializeFabric({
      canvasRef,
      fabricRef,
    });

    if (!canvas) return;

    return () => {
      canvas.dispose();
      fabricRef.current = null;
    };
  }, [canvasRef.current, fabricRef, initialize]);

  const {
    confirmPreview,
    activeImageRef,
    createPreview,
    pathRef,
    createInitialMask,
    groupRef,
    maskCanvasRef,
    resetActiveImage,
    createImage: createFauxImage,
  } = useActiveImage({
    fabricRef,
    handleToolSelection: hoistHandleToolSelection,
    saveActiveImage: () => setHasUnsavedChanges(true),
    activeTool,
    setBlock,
    setBound,
  });

  const { handleCut } = useMagicSelect({
    fabricRef,
    pathsRef: pathRef,
    groupRef,
    maskCanvasRef,
  });

  const {
    removeLasso,
    cutOutSelection,
    handleOnMouseDown,
    handleOnMouseMove,
    handleOnMouseUp,
  } = useLasso({
    fabricRef,
    activeTool,
    handleToolSelection: hoistHandleToolSelection,
    activeImageRef,
    createInitialMask,
    pathRef,
    clearAllSelection: resetActiveImage,
  });

  const { activatePaint, resetPaint } = useFeatherPaint({
    fabricRef,
    activeTool,
    groupRef,
    createInitialMask,
    pathRef,
    clearAllSelection: resetActiveImage,
  });

  const deleteShapeFromStorage = useShapesMutation(
    ({
      storage,
      data,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
      data: string;
    }) => {
      const canvasObjects = storage!.getCanvasObjects();
      canvasObjects.delete(data);
    },
    []
  );

  const deleteAllShapes = useShapesMutation(
    ({
      storage,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
    }) => {
      const canvasObjects = storage!.getCanvasObjects();
      if (!canvasObjects || canvasObjects.size === 0) return true;
      for (const [key] of canvasObjects.entries()) {
        canvasObjects.delete(key);
      }
      return canvasObjects.size === 0;
    },
    []
  );

  const syncShapeInStorage = useShapesMutation(
    ({
      storage,
      data: object,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
      data: { objectId: string; toJSON: () => any } | string;
    }) => {
      setHasUnsavedChanges(true);
      saveState();
      if (object === 'save' && saveCanvas && !isCanvas) {
        saveCanvas();
        return;
      }
      const canvasObjects = storage!.getCanvasObjects();

      if (typeof object === 'object') {
        const { objectId } = object;
        const shapeData = { ...object.toJSON(), objectId };
        canvasObjects.set(objectId, shapeData);
      }
    },
    []
  );

  const handleCanvasChange = useCallback(
    async (
      id: string,
      options?: {
        hasSketch: boolean;
      }
    ) => {
      if (hasUnsavedChanges) {
        try {
          await createDisclosure({
            message:
              'You have unsaved changes. Are you sure you want to leave?',
            title: 'Unsaved Changes',
            confirmText: 'Save Changes',
            cancelText: 'Discard Changes',
          });

          saveCanvas();

          return;
        } catch (error) {}
      }

      deleteAllShapes({});
      clearHistory();

      let routeMethod = Router.replace;

      const finalUrl = queryString.stringifyUrl(
        {
          url: Router.asPath,

          query: {
            sketchId: id,
          },
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      );

      if (!options?.hasSketch) {
        routeMethod = Router.push;

        if (canvas) {
          try {
            await canvas?.clear();
          } catch (error) {
            console.error('Error in handleCanvasChange:', error);
          }
        }
      }

      if (router.asPath !== finalUrl) {
        routeMethod(finalUrl, finalUrl, {
          shallow: true,
        });
      }

      setHasUnsavedChanges(false);
    },
    [deleteAllShapes, canvas, hasUnsavedChanges, clearHistory]
  );

  function hoistHandleToolSelection(elem: ActiveTool) {
    return handleToolSelection(elem);
  }

  const clearAllSelection = useCallback(
    (softReset?: boolean) => {
      return resetActiveImage(softReset);
    },
    [resetActiveImage]
  );

  const handleToolSelection = useCallback(
    async (elem: ActiveTool) => {
      setActiveTool(elem);

      const canvas = fabricRef.current as Required<fabric.Canvas>;
      const attributes = elem?.attributes;
      const color = attributes?.color || '#000000';
      const type = attributes?.mode || 'brush';
      const cursorSize = attributes?.strokeWidth * 3;

      switch (elem?.value) {
        case 'select':
          canvas.selection = true;
          canvas.isDrawingMode = false;
          isDrawingRef.current = false;

          initializeCursor(canvas);
          enableCanvasObjects(canvas);
          clearAllSelection();
          panning(false);

          canvas.requestRenderAll();
          break;
        case 'pan':
          canvas.selection = false;
          panning(true);
          break;
        case 'reset':
          deleteAllShapes?.({});
          fabricRef.current?.clear();
          setActiveTool(defaultNavElement);
          enableCanvasObjects(fabricRef.current as any);
          break;
        case 'delete':
          handleDelete(fabricRef.current as any, deleteShapeFromStorage as any);
          setActiveTool(defaultNavElement);
          break;

        case 'freeform':
          canvas.isDrawingMode = true;

          if (elem?.attributes?.reset) {
            resetPaint();
          }

          if (attributes?.type === 'Feather') {
            canvas.freeDrawingCursor = `url(${getDrawCursor(cursorSize, color, type)}) ${cursorSize / 2} ${cursorSize / 2}, crosshair`;
            if (attributes?.mode === 'brush') activatePaint();
            if (attributes?.mode === 'eraser') {
              try {
                await import('@/utils/canvas-lib/fabric-erasable');
                canvas.freeDrawingBrush = new (fabric as any).LassoEraserBrush(
                  canvas
                );
              } catch (e) {
                console.error(e);
              }
            }
          } else {
            canvas.freeDrawingBrush = new (fabric as any)[
              attributes?.type + 'Brush'
            ](canvas);

            canvas.freeDrawingBrush.color = color;
          }

          canvas.freeDrawingBrush.width = attributes?.strokeWidth || 5;

          canvas.requestRenderAll();
          setActiveTool({
            value: elem?.value,
            attributes: elem?.attributes,
          });

          break;

        case 'magic-select':
          canvas.isDrawingMode = false;
          initializeCursor(canvas, 'crosshair');
          disableCanvasObjects(canvas);
          canvas.selection = false;

          break;
        case 'lasso':
          setActiveTool({
            value: elem?.value,
            attributes: elem?.attributes,
          });

          if (elem?.attributes?.reset) {
            removeLasso();
          }

          canvas.isDrawingMode = false;
          initializeCursor(canvas, 'crosshair');
          canvas.selection = false;

          if (!canvas) return;
          disableCanvasObjects(canvas);

          break;

        case 'canvasBg':
          handleCanvasBackground(
            elem,
            fabricRef.current as Required<fabric.Canvas>
          );
          break;
        case 'undo':
          setActiveTool(defaultNavElement);
          undo();
          break;
        case 'redo':
          setActiveTool(defaultNavElement);
          redo();
          break;

        default:
          (selectedShapeRef as any).current = {
            value: elem?.value,
            attributes: elem?.attributes,
          };
          break;
      }
    },
    [
      deleteAllShapes,
      deleteShapeFromStorage,
      undo,
      redo,
      setActiveTool,
      panning,
      fabricRef,
      removeLasso,
      activatePaint,
      resetPaint,
      clearAllSelection,
    ]
  );

  const handleElementCutOut = useCallback(
    (options: any) => {
      const { value } = activeTool || {};
      switch (value) {
        case 'magic-select':
          handleCut();
          break;
        default:
          cutOutSelection(options);
          break;
      }

      setTimeout(() => {
        clearAllSelection();
        handleToolSelection(defaultNavElement);
      }, 10);
    },
    [
      activeTool,
      handleCut,
      cutOutSelection,
      handleToolSelection,
      clearAllSelection,
    ]
  );

  const saveCanvas = useCallback(async () => {
    if (!fabricRef.current) return;
    setHasUnsavedChanges(false);
    const getCanvasMode = (size: 'sm' | 'lg') => {
      const dataURL = fabricRef.current?.toDataURL({
        format: 'jpeg',
        quality: size === 'sm' ? 0.2 : 1,
        multiplier: size === 'sm' ? 0.5 : 1,
      });
      return convertDataURIToBuffer(dataURL!);
    };

    const canvasObjects = fabricRef.current
      .getObjects()
      .reduce((acc: any, obj: any) => {
        const { id, ...rest } = obj.toJSON();
        const objectId = id || obj.objectId;
        if (!objectId) return acc;
        return { ...acc, [objectId]: { ...rest, imageKey: obj.imageKey } };
      }, {});

    if (
      isEmpty(canvasObjects) &&
      (!fabricRef.current.backgroundImage ||
        fabricRef.current.backgroundColor === '#ffffff')
    ) {
      createToast({
        message: 'Please add some shapes to save the sketch.',
        variant: 'primary',
      });
      return;
    }

    const thumbnail = getCanvasMode('sm');
    const snapshot = getCanvasMode('lg');

    try {
      const res = await createOrUpdateSketch({
        state: {
          objects: canvasObjects,
          canvasInstance: {
            width: canvasSize.width,
            height: canvasSize.height,
            backgroundImage: fabricRef.current.backgroundImage,
            backgroundColor: fabricRef.current.backgroundColor,
          },
        },
        thumbnail,
        snapshot,
        sketchId: Router.query.sketchId as string,
        workspaceId: profile?.organizationId,
      });

      if (res?.sketch?.id && res?.sketch?.id !== Router.query.sketchId) {
        posthog.capture('sketch_created', { sketchId: res?.sketch?.id });
        handleCanvasChange(res?.sketch?.id, { hasSketch: true });
      }
    } catch (error) {
      createToast({
        message: 'Failed to save the sketch.',
        variant: 'error',
      });
      console.error('Error in useShapesMutation:', error);
    }
  }, [
    fabricRef,
    profile?.organizationId,
    createOrUpdateSketch,
    handleCanvasChange,
    createToast,
    posthog,
    canvasSize,
  ]);

  /* --------------------------------- Effects -------------------------------- */

  const { handleClipBoardActions } = useKeyEvents({
    fabricRef,
    syncShapeInStorage,
    deleteShapeFromStorage,
    undo,
    redo,
  });

  return (
    <FabricContext.Provider
      value={{
        undo,
        syncShapeInStorage,
        shapeRef,
        containerRef,
        selectedShapeRef,
        saveCanvas,
        redo,
        lasso: {
          removeLasso,
          handleOnMouseDown,
          handleOnMouseMove,
          handleOnMouseUp,
          cutOutSelection,
        },
        featherPaint: {
          activatePaint,
          resetPaint,
        },
        handleElementCutOut,
        createPreview,
        confirmPreview,
        isSketch,
        isSavingCanvas: isSaving,
        isEditingRef,
        isDrawingRef,
        hasUnsavedChanges,
        resetUnsavedChanges: () => setHasUnsavedChanges(false),
        requestSave: () => setHasUnsavedChanges(true),
        isDirty: !!pathRef.current || !!groupRef.current,
        hasWrongCanvasSize,
        handleCanvasChange,
        handleToolSelection,
        forceSetCanvasSize,
        createMask: createInitialMask,
        handleClipBoardActions,
        fabric: fabricRef,
        deleteShapeFromStorage,
        deleteAllShapes,
        canvasRef,
        createFauxImage,
        clearAllSelection,
        activeObjectRef,
        zoom,
        resetView,
        zoomToPoint,
        handleResize,
        canRedo,
        canUndo,
      }}
    >
      {children}
    </FabricContext.Provider>
  );
};
