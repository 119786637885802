import React from 'react';

import {
  CallToAction,
  Flex,
  Heading,
  Text,
  isPremiumUser,
  Tooltip,
} from '@nex/labs';

import Link from 'next/link';

import { type TUser } from '@nex/types';

import styles from '../console-layout.module.scss';
import { ContentLoader } from '@/features/console';
import { useUserStore } from '@/state/useStore';
import { formatCredit } from '@/utils/index';

export const ConsoleNexBar = ({
  profile,
  isSettings,
}: {
  profile: TUser;
  isSettings?: boolean;
}) => {
  const Motivate = {
    100: 'Great progress!',
    75: 'You are a seasoned artist!',
    50: 'You are one of us now!',
    25: 'You are a taste maker!',
    5: 'You are a trend setter!',
    0: 'Upgrade to continue',
  };

  const userCredit = +profile?.credit! || 0;
  const { isLoading: isProfileLoading } = useUserStore();
  const finalPercent =
    Math.max(0, Math.floor((userCredit / profile?.totalCredited!) * 100)) || 0;

  function getMessageForPercent(percent: number) {
    if (percent === 0) return Motivate[0];
    if (percent <= 5) return Motivate[0];
    if (percent <= 25) return Motivate[25];
    if (percent <= 50) return Motivate[50];
    if (percent <= 75) return Motivate[75];
    if (percent <= 100) return Motivate[100];
  }

  if (isProfileLoading || !profile.id)
    return <ContentLoader className="w-full h-[148px] " />;

  return (
    <div className={styles.PlanReading}>
      <Flex justifyContent="space-between">
        <Heading.h6 weight={700}> Used Credit</Heading.h6>

        <Text fontSize="10px" className="opacity-70">
          <Link href="/help" className="underline">
            How we calculate
          </Link>
        </Text>
      </Flex>

      <progress
        value={finalPercent}
        max="100"
        style={
          {
            '--webkitProgressPercent': `${finalPercent}%`,
          } as React.CSSProperties
        }
      />
      <Text>
        You have
        <Text.span
          className="mx-1"
          weight={700}
          color={userCredit > 5 ? 'var(--primary-theme)' : 'var(--primary-red)'}
        >
          {formatCredit(userCredit)} credits
        </Text.span>
        remaining{' '}
        {isSettings && (
          <>
            in your{' '}
            <Tooltip
              content={`You can generate ${Math.max(
                userCredit,
                0
              )} images per month on the ${profile?.subscription?.tier || 'Nex'} plan.`}
            >
              <span className="underline">
                {profile?.subscription?.tier?.replace?.('NEX', '')} plan{' '}
              </span>
            </Tooltip>
            . {getMessageForPercent(finalPercent)}
          </>
        )}
      </Text>

      {!isPremiumUser(profile) && !isSettings && (
        <CallToAction.a
          size="xs"
          variant={'secondary'}
          className="mt-2"
          href="/settings/plans"
        >
          Upgrade Now
        </CallToAction.a>
      )}
    </div>
  );
};
