import React, { useEffect, useMemo, useRef } from 'react';
import { fabric } from 'fabric';
import classNames from 'classnames';
import Router, { useRouter } from 'next/router';
import { useOrganization } from '@clerk/nextjs';
import { Flex, Spinner, Text, useLocalStorage } from '@nex/labs';

import { useGetSketchQuery } from '@/state/query/block';
import { useCanvasStore } from '@/state/useStore';

import { renderCanvas } from '@/utils/canvas-lib/canvas';
import { handleImageUpload } from '@/utils/canvas-lib/shapes';
import { useKeyEvents, useCanvasActions } from '@/utils/canvas-lib';

import { FloatingNav } from './components/floating-nav';
import { CanvasFrame } from './components/sketcher';
import { useFabric } from '@/components/layout';

import styles from './sketch.module.scss';

export const ArtboardSketch: React.FC = () => {
  const { activeTool, setCanvasSize } = useCanvasStore();

  const [rootStorage] = useLocalStorage('canvasObjects', {});
  const router = useRouter();
  const { organization } = useOrganization();

  const {
    data: sketchData,
    isLoading,
    isFetching,
  } = useGetSketchQuery(
    {
      sketchId: router.query.sketchId as string,
      workspaceId: organization?.publicMetadata?.externalId,
    },
    {
      enabled: router.query.sketchId !== 'new' && !!router.query.sketchId,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const rootCanvasObjects = useMemo(() => {
    const apiObjects = sketchData?.sketch?.state?.objects || {};
    return apiObjects ? Object.values(apiObjects) : rootStorage;
  }, [sketchData?.sketch?.state?.objects, rootStorage]);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const {
    fabric: fabricRef,
    handleToolSelection,
    syncShapeInStorage,
    isSavingCanvas,
    canvasRef,
    shapeRef,
    activeObjectRef,
    canUndo,
    canRedo,
  } = useFabric();

  useCanvasActions({
    syncShapeInStorage,
  });

  useEffect(() => {
    const { width, height, aspectRatio } =
      sketchData?.sketch?.state?.canvasInstance || {};

    setCanvasSize({
      width,
      height,
      aspectRatio,
    });
  }, [sketchData?.sketch?.state?.canvasInstance]);

  useEffect(() => {
    renderCanvas({
      fabricRef,
      canvasInstance: sketchData?.sketch?.state?.canvasInstance,
      handleToolSelection,
      canvasObjects: rootCanvasObjects,
      activeObjectRef,
    });
  }, [rootCanvasObjects]);

  const isLoadingSketch =
    Router?.query?.sketchId !== 'new' && isFetching && isLoading;

  return (
    <>
      <FloatingNav
        imageInputRef={imageInputRef}
        activeTool={activeTool}
        hasHistory={canUndo}
        hasRedoHistory={canRedo}
        saveCanvas={() => syncShapeInStorage('save')}
        handleImageUpload={async (e: any, key?: string) => {
          e.stopPropagation();
          await handleImageUpload({
            file: e.target.files[0],
            canvas: fabricRef as any,
            shapeRef,
            imageKey: key,
            syncShapeInStorage,
          });
        }}
        handleToolSelection={handleToolSelection}
      />
      {isSavingCanvas && (
        <div className={styles.Saving}>
          <Spinner size={16} /> <Text> Saving...</Text>
        </div>
      )}

      <Flex gap={12}>
        {isLoadingSketch && <LoadingOverlay />}
        <SketchArea
          isRealTime={false}
          isLoadingSketch={isLoadingSketch}
          canvasRef={canvasRef}
          fabricRef={fabricRef}
        />
        {/* <RealtimeSection
          fabricRef={fabricRef}
          dataURL={dataURL}
          setDataURL={setDataURL}
          isRealTime={isRealTime}
        /> */}
      </Flex>
    </>
  );
};

const LoadingOverlay: React.FC = () => (
  <div className="absolute inset-0 z-50 opacity-1">
    <Spinner
      center
      text="Loading your sketch. Please wait a moment."
      spinner="logo"
      style={{ margin: '92px auto' }}
    />
  </div>
);

const SketchArea: React.FC<{
  isRealTime: boolean;
  isLoadingSketch: boolean;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  fabricRef: React.RefObject<fabric.Canvas>;
}> = ({ isRealTime, isLoadingSketch, canvasRef, fabricRef }) => (
  <div
    className={classNames([
      isRealTime && 'max-w-[50%] w-full h-full overflow-hidden',
      isRealTime && styles.Wrapper,
      !isRealTime && 'w-full h-full',
      isLoadingSketch && 'opacity-0',
    ])}
  >
    <CanvasFrame canvasRef={canvasRef} fabricRef={fabricRef} />
  </div>
);
