import { useArtboardStore, useCanvasStore } from '@/state/useStore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { aspectRatio } from '@/features/console/artboard/utils/constants';

interface CanvasSize {
  width: number;
  height: number;
  scale?: number;
}

interface UseCanvasSizeProps {
  isCanvas: boolean;
  fabric: React.MutableRefObject<fabric.Canvas | null>;
}

interface UseCanvasSizeReturn {
  canvasSize: CanvasSize;
  hasWrongCanvasSize: boolean;
  forceSetCanvasSize: (size: CanvasSize) => void;
  handleResize: (e?: Window | any) => Promise<void>;
}

const DEFAULT_CANVAS_WIDTH = 800;
const DEFAULT_CANVAS_HEIGHT = 600;

const parseResolution = (resolution: string): CanvasSize => {
  const [width, height] = resolution.split('x').map(Number);

  return { width, height };
};

export const useCanvasSize = ({
  isCanvas,
  fabric,
}: UseCanvasSizeProps): UseCanvasSizeReturn => {
  const { defaultConfig: defConfig } = useArtboardStore();
  const { canvasSize: sketchSize } = useCanvasStore();

  const defaultConfig = useMemo(() => {
    if (isCanvas) {
      const resolution = `${sketchSize?.width}x${sketchSize?.height}`;
      return { resolution };
    }

    return { resolution: defConfig.resolution };
  }, [defConfig.resolution, isCanvas, sketchSize?.height, sketchSize?.width]);

  const cachedDefaultConfig = useRef(defaultConfig.resolution);

  const [canvasSize, setCanvasSize] = useState<CanvasSize>({
    width: DEFAULT_CANVAS_WIDTH,
    height: DEFAULT_CANVAS_HEIGHT,
  });

  const findNearestAspectRatio = (width: string, height: string): string => {
    const targetRatio = Number(width) / Number(height);

    return aspectRatio.reduce((nearest, current) => {
      const [w, h] = current.split('x').map(Number);
      const ratio = w / h;
      const currentDiff = Math.abs(targetRatio - ratio);
      const [nearestW, nearestH] = nearest.split('x').map(Number);
      const nearestDiff = Math.abs(targetRatio - nearestW / nearestH);

      return currentDiff < nearestDiff ? current : nearest;
    }, aspectRatio[0]);
  };

  const checkAspectRatio = useCallback(
    (defaultResolution: string): boolean => {
      const nearestRatio = findNearestAspectRatio(
        String(canvasSize.width),
        String(canvasSize.height)
      );
      return nearestRatio !== defaultResolution;
    },
    [canvasSize.width, canvasSize.height]
  );

  const hasWrongCanvasSize = useMemo(() => {
    if (sketchSize?.aspectRatio) {
      return defaultConfig.resolution !== sketchSize.aspectRatio;
    }
    return checkAspectRatio(defaultConfig.resolution);
  }, [defaultConfig.resolution, sketchSize?.aspectRatio, checkAspectRatio]);

  useEffect(() => {
    if (!fabric.current) {
      setCanvasSize({
        width: 0,
        height: 0,
      });
    }

    const updateCanvasSize = () => {
      if (cachedDefaultConfig.current !== defaultConfig.resolution) {
        cachedDefaultConfig.current = defaultConfig.resolution;
        const { width, height } = parseResolution(defaultConfig.resolution);
        setCanvasSize({ width, height });

        return;
      }

      setCanvasSize({
        width:
          sketchSize?.width || parseResolution(defaultConfig.resolution).width,
        height:
          sketchSize?.height ||
          parseResolution(defaultConfig.resolution).height,
      });
    };

    updateCanvasSize();
  }, [
    defaultConfig.resolution,
    fabric,
    isCanvas,
    sketchSize?.width,
    sketchSize?.height,
  ]);

  const handleResize = useCallback(
    async (e?: Window | any) => {
      const canvas = fabric?.current;
      if (!canvas) return;
      if (typeof window === 'undefined') return;

      const getSize = () => {
        const { width, height } = canvasSize;
        const asideWidth = document
          .querySelector('#artboard-aside')
          ?.getBoundingClientRect();
        const previewWidth = document
          .querySelector('#artboard-preview')
          ?.getBoundingClientRect();

        const asidesWidth =
          (asideWidth?.width || 0) + (previewWidth?.width || 0);
        const finalWindowWidth =
          (!e ? window.innerWidth : e.target.innerWidth) - asidesWidth - 24;
        let scale = 1.05;

        if (+width > +finalWindowWidth) {
          scale = Math.min(finalWindowWidth / +width, 1);
        }

        return {
          width: parseInt(`${width}`),
          height: parseInt(`${height}`),
          scale,
        };
      };

      const {
        width: originalWidth,
        height: originalHeight,
        scale,
      } = getSize() as Required<CanvasSize>;

      const canvasElement = document.getElementById('canvas');

      if (!canvasElement) return;

      canvasElement.style.width = `${parseInt(`${originalWidth}`) * scale}px`;
      canvasElement.style.height = `${parseInt(`${originalHeight}`) * scale}px`;

      if (canvas) {
        try {
          await Promise.all([
            canvas.setWidth(canvasElement.clientWidth),
            canvas.setHeight(canvasElement.clientHeight),
            canvas.setZoom(Math.min(scale, 1)),

            canvas.renderAll(),
          ]);
        } catch (error) {
          console.error('Error in handleCanvasChange:', error);
        }
      }
    },
    [canvasSize, fabric]
  );

  const forceSetCanvasSize = useCallback(
    ({ width, height }: CanvasSize) => {
      setCanvasSize({ width, height });
      handleResize();
    },
    [handleResize]
  );

  return {
    canvasSize,
    forceSetCanvasSize,
    hasWrongCanvasSize,
    handleResize,
  };
};
