import { useEffect } from 'react';
import { pusher } from '@lib/pusher';
import { useArtboardStore, useUserStore } from '@/state/useStore';
import { usePostHog } from 'posthog-js/react';
import { useFeedback } from '@nex/labs';
import { useArtboardQueryData } from '../artboard';

export const usePusher = () => {
  const { artboard } = useArtboardQueryData();
  const { setCollaborators } = useArtboardStore();
  const { profile } = useUserStore();
  const { createToast } = useFeedback();

  const posthog = usePostHog();
  useEffect(() => {
    if (!artboard?.id) return;
    pusher.signin();

    const getCollaborators = (channel: any) => {
      const members = { ...channel.members };
      return Object.values(members.members);
    };

    const channelName = `presence-artboard-${artboard.id}`;
    const channel = pusher.subscribe(channelName);

    channel.bind('pusher:subscription_succeeded', (members: any) => {
      setCollaborators(getCollaborators(channel));
    });

    channel.bind('pusher:member_added', () => {
      posthog.capture('collaboration_member_added', {
        user: channel.name,
      });

      setCollaborators(getCollaborators(channel));
    });

    channel.bind('pusher:member_removed', () => {
      posthog.capture('collaboration_member_removed', {
        user: channel.name,
      });

      setCollaborators(getCollaborators(channel));
    });

    channel.bind(
      'nex:composition_created',
      (data: {
        compositionId: string;
        artboardId: string;
        creator: {
          id: string;
          name: string;
          photo: string;
        } | null;
      }) => {
        if (data?.creator?.id === profile?.id) return;
        createToast({
          message: `${data.creator?.name} has added a new composition`,
          variant: 'primary',
        });
      }
    );

    return () => {
      channel.unsubscribe();
    };
  }, [artboard?.id, profile?.id]);
};
