import React, { useEffect } from 'react';

import {
  Field,
  Flex,
  Text,
  useMediaQuery,
  Avatar,
  Popover,
  CallToAction,
} from '@nex/labs';
import { useAuth, useClerk } from '@clerk/nextjs';

import Link from 'next/link';
import Router, { useRouter } from 'next/router';

import Logo from '@nex/icons/svg/logo.svg';

import Search from '@nex/icons/svg/console/search.svg';
import InputSearch from '@nex/icons/svg/misc/search.svg';

import { usePostHog } from 'posthog-js/react';

import { useUserStore } from '@/state/useStore';
import styles from './console-header-nav.module.scss';
import { HeaderNavMenu } from '../header-nav-menu/index';
import { WorkspaceSwitcher } from '@/features/console/console-home/components/workspace-switcher';
import { UserButton } from '@/components/misc/float-bar';
import { formatCredit } from '@/utils/index';

export const ConsoleHeaderNav = () => {
  const router = useRouter();
  const { user } = useClerk();
  const isMobile = useMediaQuery('lg', 'greaterThan');
  const { profile } = useUserStore();
  const [searchOpen, setSearchOpen] = React.useState(true);

  useEffect(() => {
    if (router.query.q || isMobile) {
      setSearchOpen(false);
    }
  }, [router.query.q, isMobile]);

  return (
    <Flex className={styles.ConsoleLayoutHeaderNav}>
      <Flex className={styles.ConsoleLayoutHeaderNavLogo} gap={32}>
        <Link href="/">
          <Logo width="50" height="auto" />
        </Link>
        <HeaderNavMenu />
      </Flex>

      {/* TODO:  */}
      <Flex alignItems="center" gap={18}>
        <Flex alignItems="center" gap={18}>
          <WorkspaceSwitcher
            onShowCreateWorkspace={() => {
              // console.log('Create Workspace');
            }}
          />

          <Flex
            alignItems="center"
            gap={18}
            className={styles.ConsoleMetaCredit}
          >
            <Text className="opacity-60" weight={500}>
              {formatCredit(profile?.credit)} Credits
            </Text>
            <CallToAction.a
              href="/settings/plans"
              size="xs"
              className={styles.ConsoleMetaCreditCTA}
            >
              <Text weight={600}>Upgrade</Text>
            </CallToAction.a>
          </Flex>
        </Flex>
        <UserButton />
      </Flex>
    </Flex>
  );
};

export const UserActions = () => {
  const posthog = usePostHog();
  const { signOut } = useAuth();
  const { profile, logOut } = useUserStore();

  const handleLogout = () => {
    logOut();
    signOut();
    posthog.reset();
  };

  return (
    <Popover size="sm" closeOnScroll>
      <Popover.Trigger>
        <Avatar size={40} alt={profile?.name} src={profile?.photo} />
      </Popover.Trigger>
      <Popover.Content isDropdown>
        <Link href="/">Home</Link>
        <Link href="/library">My Collections</Link>
        <Link href="/showcase">Showcase</Link>
        <Link href="/settings">Profile</Link>
        <Link href="/help">Help</Link>
        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </Popover.Content>
    </Popover>
  );
};
