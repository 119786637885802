import { createContext } from 'react';
import { type FabricContextType } from './types';

export const FabricContext = createContext<FabricContextType>({
  fabric: { current: null },
  isSketch: false,
  isSavingCanvas: false,
  zoom: 1,
  hasUnsavedChanges: false,
  resetUnsavedChanges: () => {},
  syncShapeInStorage: async () => {},
  handleCanvasChange: () => {},
  saveCanvas: () => {},
  deleteShapeFromStorage: async () => {},
  deleteAllShapes: async () => {},
  canvasRef: { current: null },
  containerRef: { current: null },
  selectedShapeRef: { current: {} },
  shapeRef: { current: null },
  activeObjectRef: { current: null },
  isDrawingRef: { current: false },
  isEditingRef: { current: false },
  hasWrongCanvasSize: false,
  isDirty: false,
  resetView: () => {},
  zoomToPoint: () => {},
  forceSetCanvasSize: () => {},
  handleToolSelection: () => {},
  undo: () => {},
  redo: () => {},
  requestSave: () => {},
  canUndo: false,
  canRedo: false,
  handleResize: () => {},
  lasso: {
    removeLasso: () => {},
    handleOnMouseDown: () => {},
    handleOnMouseMove: () => {},
    handleOnMouseUp: () => {},
    cutOutSelection: (options?: { asNewLayer?: boolean }) => {},
  },
  handleElementCutOut: () => {},
  featherPaint: {
    activatePaint: () => {},
    resetPaint: () => {},
  },
  handleClipBoardActions: () => {},
  createPreview: () => {},
  confirmPreview: () => {},
  createFauxImage: () => {},
  clearAllSelection: () => {},
  createMask: () => {},
});
