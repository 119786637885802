import React, { useEffect, useMemo } from 'react';

import { ASSETS_CONSTANTS } from '@/features/console/artboard/utils/constants';
import { useArtboardStore } from '@/state/useStore';

import { Flex, Text } from '@nex/labs';
import { IconMap } from './controls-icon';

export const UseAsControl = ({
  imageURL,
  imageKey,
  onSelect,
  defaultBehavior = true,
}: {
  imageURL?: string;
  imageKey?: string;
  onSelect?: (block: any) => void;
  defaultBehavior?: boolean;
}) => {
  const { setBlock, defaultConfig, models, setConfig } = useArtboardStore();
  const modelBlocks =
    defaultConfig?.modelBlocks || defaultConfig?.model?.blocks;
  return (
    <>
      {modelBlocks?.length ? (
        modelBlocks?.map((block: any, index: number) => {
          const Icon = IconMap[block as keyof typeof IconMap];
          return (
            <button
              key={index}
              onClick={() => {
                if (onSelect) {
                  onSelect(block);
                  if (!defaultBehavior) return;
                }

                setBlock('assets', {
                  src: imageURL,
                  key: imageKey,
                  name: block.name,
                  subMetaInfo: ASSETS_CONSTANTS.find(
                    (item) => item.key === block
                  ),
                  weight: 0.5,
                  subMeta: block,
                });
              }}
            >
              <Flex.Row alignItems="center" gap={4}>
                <Icon className="[&>path] fill-current text-black" />
                {ASSETS_CONSTANTS.find((item) => item.key === block)?.title}
              </Flex.Row>
            </button>
          );
        })
      ) : (
        <button
          onClick={() => {
            const newModel = models?.find(
              (model: any) => model.platform === 'nex'
            );

            if (newModel) {
              setConfig({
                model: newModel,
              });
            }
          }}
        >
          <Flex.Row alignItems="center" gap={4}>
            Switch to Ikon-1 to use controls
          </Flex.Row>
        </button>
      )}
    </>
  );
};
