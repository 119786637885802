import { useState } from 'react';

import {
  CallToAction,
  Empty,
  Field,
  Flex,
  Img,
  Spinner,
  getDataIcons,
  Text,
  useDebounce,
  Tooltip,
} from '@nex/labs';

import { useArtboardStore } from '@/state/useStore';
import { useGetModelPresetsQuery } from '@/state/query/prompt';

import { isBlockInView } from '@/utils';
import { motion } from 'framer-motion';

import styles from './views.module.scss';

export const ArtboardPresetsBlock = () => {
  const [query, setSearchValue] = useState<string | null>(null);
  const { setBlock, removeBlock, defaultConfig } = useArtboardStore();
  const searchValue = useDebounce(query, 500);
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetModelPresetsQuery(
      {
        search: searchValue as string,
        modelId: defaultConfig?.model?.id,
      },
      {
        enabled:
          !!defaultConfig?.model?.id &&
          typeof defaultConfig?.model?.id === 'string',
      }
    );

  const BLOCK_TYPE = 'preset';
  const blockIsInView = isBlockInView(BLOCK_TYPE)?.[0];

  return (
    <Flex.Column gap={18}>
      <Text weight={600} className="-mb-2">
        Styles{' '}
        <Tooltip content="Style presets. Hover over the one you want to add to your generation and click “Add Block”">
          <img
            src={getDataIcons('help')}
            className="inline w-[12px] -mt-[2px]"
          />
        </Tooltip>
      </Text>

      <>
        <Field.Input
          value={query || ''}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search for styles"
          leadingIcon={<img src={getDataIcons('search', '#667085')} />}
        />
        <Flex.Column>
          <Flex.Row gap={12}>
            {data?.presets?.map((preset, i) => (
              <motion.button
                key={i}
                className={styles.PresetButton}
                whileHover={{
                  scale: 1.04,
                  rotate: -1,
                  transition: {
                    duration: 0.3,
                    ease: 'easeInOut',
                    type: 'spring',
                    stiffness: 100,
                  },
                }}
                style={{
                  border:
                    blockIsInView?.data?.id === preset?.id
                      ? '4px solid var(--primary-theme)'
                      : '4px solid transparent',
                }}
                onClick={() => {
                  if (blockIsInView?.data?.id === preset?.id) {
                    removeBlock(BLOCK_TYPE);
                    return;
                  }

                  setBlock(BLOCK_TYPE, {
                    ...preset,
                    src: preset?.thumbnail,
                  });
                }}
              >
                <Img src={preset?.thumbnail!} alt={preset?.name} />

                <Text
                  weight={700}
                  fontSize="var(--font-caption)"
                  align="center"
                >
                  {preset?.name}
                </Text>
              </motion.button>
            ))}
          </Flex.Row>
          {(isLoading || isFetching) && (
            <Spinner spinner="logo" center size={24} />
          )}

          {hasNextPage && (
            <CallToAction.button
              size="sm"
              variant="secondary"
              className="mt-4 mx-auto basis-[100%]"
              isLoading={isLoading}
              onClick={() => fetchNextPage()}
            >
              Load More
            </CallToAction.button>
          )}
        </Flex.Column>
        {!(isLoading || isFetching) && data?.presets?.length === 0 && (
          <Empty message="No styles found" size="md" />
        )}{' '}
      </>
    </Flex.Column>
  );
};
