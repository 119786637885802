import React, { useEffect, useMemo } from 'react';
import Router, { useRouter } from 'next/router';
import {
  Avatar,
  CallToAction,
  Flex,
  Img,
  useMediaQuery,
  Tooltip,
  cleanCompositions,
  extractImage,
  getDataIcons,
} from '@nex/labs';

import classNames from 'classnames';

import { ArtboardContent } from './components/generations';
import { ArtboardSketch } from './components/sketch';
import { CollaborateModule } from './components/collaborate';

import HistoryIcon from '@nex/icons/svg/blocks/history.svg';
import Menu from '@nex/icons/svg/misc/menu-alt.svg';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import Cursor from '@nex/icons/svg/blocks/sketch/cursor.svg';

import { useArtboardStore, useUserStore } from '@/state/useStore';

import { useFabric } from '@/components/layout';
import styles from './artboard.module.scss';
import { ActionBar } from './components/action-bar';
import { FloatBar } from '@/components/misc/float-bar';
import { useQueryData } from '@/utils/hooks/useQueryData';
import { blockQueryKeys } from '@/state/query/block';

export const Artboard = ({}: {}) => {
  const { collaborators, setLayoutBar, setCurrentGeneration } =
    useArtboardStore();
  const { isSketch } = useFabric();
  const router = useRouter();
  const [showHistory, setShowHistory] = React.useState(false);
  const { artboard } = useArtboardQueryData();

  const isMobile = useMediaQuery('lg', 'greaterThan');

  useEffect(() => {
    if (router.query.showHistory) setShowHistory(true);
    if (isSketch) setShowHistory(false);
  }, [router.query.showHistory, isSketch]);

  useMemo(() => {
    if (Router.query.__clerk_created_session) {
      Router.replace(
        Router.asPath.replace('/artboard/new', '/create-account'),
        undefined,
        {
          shallow: true,
        }
      );
    }
  }, []);

  const history = cleanCompositions(artboard?.compositions).map(
    (group: any) => {
      return {
        firstImage: extractImage(group?.images?.[0]),
        compositions: group,
        images: group?.images?.[0],
      };
    }
  );

  const bareMode = !showHistory && !isSketch;

  return (
    <>
      {bareMode && (
        <>
          <div
            className={classNames([
              styles.FloatingNav,
              styles.NavToolBar,

              (collaborators && collaborators?.length > 1) || isMobile
                ? styles.onlyShow
                : styles.hide,
            ])}
          >
            <Flex className="flex lg:hidden" gap={8} alignItems="center">
              <button
                onClick={() => {
                  setLayoutBar('block');
                }}
                style={{
                  background: 'var(--primary-theme)',
                }}
              >
                <Menu className="rotate-180 mr-2" width={14} />
                New Block
              </button>
            </Flex>
          </div>
          {bareMode && (
            <div
              className={classNames([
                styles.FloatingNav,
                styles.NavToolBar,
                'lg:top-[18px] top-[72px]',
              ])}
              id="floating-nav-top"
            >
              {history?.length > 0 && (
                <Flex gap={4} scrollable className="max-w-[200px]">
                  {history?.map((gen: any, index: number) => (
                    <Img
                      onClick={(e: any) => {
                        if (e.target.tagName !== 'IMG') return;
                        setCurrentGeneration({
                          ...gen.images,
                          parent: gen?.compositions,
                        });
                      }}
                      key={index}
                      src={gen?.firstImage}
                      className="rounded-md h-[40px] basis-[40px] flex-shrink-0 flex-grow-0 cursor-pointer"
                      alt="history"
                      size={'object-cover'}
                      width={40}
                      height={40}
                    />
                  ))}
                </Flex>
              )}

              {/* <button
                onClick={() => {
                  setShowHistory((prev) => !prev);
                }}
                disabled={history?.length === 0}
              >
                <HistoryIcon />
                History
              </button> */}
              {collaborators && collaborators?.length > 1 ? (
                <div className={classNames([styles.collaborators, 'ml-6'])}>
                  <Cursor />

                  {collaborators
                    .slice(0, 8)
                    ?.map((collaborator: any, index: number) => (
                      <Tooltip
                        tooltip={collaborator.name}
                        key={`${collaborator.name}:collaborate_avatar:${index}`}
                        outSet="default"
                      >
                        <Avatar
                          src={collaborator.photo}
                          alt={collaborator.name}
                          size={28}
                        />
                      </Tooltip>
                    ))}
                </div>
              ) : null}
              <CollaborateModule />
            </div>
          )}
          <FloatBar />
        </>
      )}

      <div className={styles.ArtboardMainContent}>
        {showHistory && (
          <Flex.Row className="p-[14px]">
            <CallToAction.button
              onClick={() => {
                setShowHistory((prev) => !prev);
              }}
              size="sm"
              variant="secondary"
              leadingIcon={
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  className="rotate-90 w-3 h-3"
                />
              }
            >
              Back
            </CallToAction.button>
          </Flex.Row>
        )}

        {isSketch ? (
          <ArtboardSketch />
        ) : (
          <ArtboardContent showHistory={showHistory} />
        )}
      </div>

      {!isSketch && (
        <div
          className={classNames([styles.FloatingNav, styles.ActionBar])}
          id="floating-nav-bottom"
        >
          <ActionBar />
        </div>
      )}
    </>
  );
};

export const useArtboardQueryData = () => {
  const { profile } = useUserStore();
  const { data: artboard } = useQueryData(
    [
      blockQueryKeys.getArtboard,
      {
        artboardId: Router.query.id,
        workspaceId: profile?.organizationId,
        limit: 20,
      },
    ],
    {
      enabled: !!Router.query.id && Router.query.id !== 'new',
      isInfinite: true,
      select: (data) => {
        const { ...artboardData } = data?.[data.length - 1]?.artboard || {};
        const compositions = data?.map(
          (data: any) => data?.artboard?.compositions
        );
        return {
          ...artboardData,
          compositions: [
            ...(data?.map((data: any) => data?.artboard?.compositions).flat() ||
              []),
          ],
        };
      },
    }
  );

  return {
    artboard,
  };
};
