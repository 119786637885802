import React, {
  use,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fabric } from 'fabric';
import { useCanvasStore, useGlobalStore } from '@/state/useStore';
import { handleCanvasPopup } from '../canvas';

export const useMagicSelect = ({
  fabricRef,
  pathsRef,
  groupRef: currentGroup,
  maskCanvasRef,
}: {
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
  pathsRef: React.MutableRefObject<any[]>;
  groupRef: React.MutableRefObject<fabric.Group | null>;
  maskCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
}) => {
  const { activeTool, setPopoverBounds: setBound } = useCanvasStore();
  const { setOverlayLoading } = useGlobalStore();

  const [points, setPoints] = useState<{ point: number[]; label: number }[]>(
    []
  );

  const [modelReady, setModelReady] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const workerRef = useRef<Worker>();
  const isMagicSelectMode = useMemo(
    () => activeTool?.value === 'magic-select',
    [activeTool]
  );

  const initializeWorker = useCallback(() => {
    workerRef.current = new Worker('/workers/segment-worker.js', {
      type: 'module',
    });
    workerRef.current.postMessage({ type: 'init' });
    workerRef.current.onmessage = handleWorkerMessage;

    return () => workerRef.current?.terminate();
  }, []);

  useEffect(() => {
    return initializeWorker();
  }, [initializeWorker]);

  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas || !isMagicSelectMode) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'z' && e.metaKey) {
        e.preventDefault();
        const lastPoint = pathsRef.current.pop();
        if (lastPoint) {
          canvas.remove(lastPoint);
          setPoints((prev) => prev.slice(0, -1));
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [fabricRef, isMagicSelectMode, pathsRef]);

  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas || !isMagicSelectMode) return;

    const extractFabricCanvas = canvas.toDataURL({ format: 'png' });
    workerRef.current?.postMessage({
      type: 'segment',
      data: extractFabricCanvas,
    });
  }, [fabricRef, isMagicSelectMode]);

  const handleDecoding = useCallback(
    (points: any) => {
      if (!workerRef.current) return;
      setIsProcessing(true);
      workerRef.current.postMessage({ type: 'decode', data: points });
    },
    [workerRef]
  );

  useEffect(() => {
    const canvas = fabricRef.current as any;
    if (!canvas) return;

    if (isMagicSelectMode) {
      maskCanvasRef.current = document.createElement('canvas');
      const overlay = maskCanvasRef.current;

      overlay.style.position = 'absolute';
      overlay.style.pointerEvents = 'none';
      overlay.width = canvas.width || 0;
      overlay.height = canvas.height || 0;

      overlay.style.top = canvas.upperCanvasEl.offsetTop + 'px';
      overlay.style.left = canvas.upperCanvasEl.offsetLeft + 'px';

      canvas.wrapperEl.appendChild(overlay);
    }
  }, [fabricRef.current, isMagicSelectMode, maskCanvasRef]);

  const handleMaskResult = useCallback(
    (data: { mask: any; scores: number[] }) => {
      const { mask, scores } = data;
      const overlay = maskCanvasRef.current;
      if (!overlay) return;

      const ctx = overlay.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, overlay.width, overlay.height);

      const imageData = ctx.createImageData(mask.width, mask.height);

      const bestIndex = scores.reduce(
        (best: any, score: number, i: number) =>
          score > scores[best] ? i : best,
        0
      );

      for (let i = 0; i < imageData.data.length / 4; i++) {
        if (mask.data[scores.length * i + bestIndex] === 1) {
          const offset = 4 * i;
          imageData.data[offset] = 0;
          imageData.data[offset + 1] = 114;
          imageData.data[offset + 2] = 189;
          imageData.data[offset + 3] = 128;
        }
      }

      ctx.putImageData(imageData, 0, 0);
    },
    [maskCanvasRef]
  );

  // i decided to not go the route where i add the mask to the canvas
  // const handleMaskResult = useCallback(
  //   (data) => {
  //     const { mask, scores } = data;
  //     const canvas = fabricRef.current;
  //     maskCanvasRef.current =
  //       maskCanvasRef.current || document.createElement('canvas');
  //     const maskCanvas = maskCanvasRef.current;
  //     if (!canvas) return;

  //     maskCanvas.width = mask.width;
  //     maskCanvas.height = mask.height;
  //     const ctx = maskCanvas.getContext('2d');
  //     const imageData = ctx.createImageData(mask.width, mask.height);

  //     const bestIndex = scores.reduce(
  //       (best, score, i) => (score > scores[best] ? i : best),
  //       0
  //     );

  //     for (let i = 0; i < imageData.data.length / 4; i++) {
  //       if (mask.data[scores.length * i + bestIndex] === 1) {
  //         const offset = 4 * i;
  //         imageData.data[offset] = 0; // R
  //         imageData.data[offset + 1] = 114; // G
  //         imageData.data[offset + 2] = 189; // B
  //         imageData.data[offset + 3] = 255; // A
  //       }
  //     }

  //     ctx.putImageData(imageData, 0, 0);

  //     const fabricImage = new fabric.Image(maskCanvas, {
  //       left: 0,
  //       top: 0,
  //       selectable: false,
  //       hasControls: false,
  //       hasBorders: false,
  //       hasRotatingPoint: false,
  //       disableFromLayer: true,
  //       evented: false,
  //       opacity: 0.5,
  //     } as any);

  //     canvas.add(fabricImage);
  //     canvas.requestRenderAll();
  //   },
  //   [fabricRef, maskCanvasRef]
  // );

  const handleWorkerMessage = useCallback(
    (e: {
      data: {
        type: string;
        data?: any;
        error?: any;
      };
    }) => {
      const { type, data, error } = e.data;
      switch (type) {
        case 'ready':
          setModelReady(true);
          break;
        case 'segment_result':
          if (data === 'done') {
            setOverlayLoading(false);
            setModelReady(true);
            return;
          } else {
            setOverlayLoading(true);
          }
          break;
        case 'decode_result':
          handleMaskResult(data);
          setIsProcessing(false);
          break;

        case 'error':
          setIsProcessing(false);
          break;
      }
    },
    [handleMaskResult]
  );

  const handleSegmentation = useCallback(
    async (pointsData: any) => {
      const canvas = fabricRef.current;
      if (!canvas) return;

      const imageDataURI = canvas.toDataURL({ format: 'png' });

      if (!imageDataURI) return;

      try {
        if (modelReady) {
          handleDecoding(pointsData);
          return;
        }
      } catch (error) {
        console.error('Segmentation error:', error);
      } finally {
        // should remove circle points from canvas ?
      }
    },
    [fabricRef, handleDecoding, modelReady]
  );

  const addPoint = (point: any, isPositive = true) => {
    const canvas = fabricRef.current;

    if (!canvas) return;

    const circle: any = new fabric.Circle({
      left: point.x - 5,
      top: point.y - 5,
      radius: 5,
      fill: isPositive ? '#00ff00' : '#ff0000',
      stroke: '#ffffff',
      disableFromLayer: true,
      strokeWidth: 2,
      selectable: false,
    } as any);

    if (Array.isArray(pathsRef.current)) {
      pathsRef.current.push(circle);
    } else {
      pathsRef.current = [circle];
    }

    circle.set('objType', 'point');

    if (!currentGroup.current) {
      currentGroup.current = new (fabric as any).Group([], {
        selectable: false,
        hasControls: false,
        hasBorders: false,
        hasRotatingPoint: false,
        disableFromLayer: true,
        erasable: 'deep',
      });

      canvas.add(currentGroup.current!);
    }

    addToGroup();
    setPoints((prev) => {
      const newPoints = [
        ...prev,
        {
          point: [point.x / canvas.width!, point.y / canvas.height!],
          label: isPositive ? 1 : 0,
        },
      ];

      handleSegmentation(newPoints);

      return newPoints;
    });
  };

  const unGroup = useCallback(() => {
    const canvas = fabricRef.current;

    if (!canvas || !currentGroup.current) return;

    let group = currentGroup.current;
    let objects = group.getObjects();

    objects.forEach((obj) => {
      group.removeWithUpdate(obj);
      canvas.add(obj);
    });
  }, [fabricRef, currentGroup]);

  const addToGroup = () => {
    const canvas = fabricRef.current;

    if (!canvas || !currentGroup.current) return;

    unGroup();

    pathsRef.current.forEach((obj: any) => {
      canvas.remove(obj);
      currentGroup.current!.addWithUpdate(obj);
    });

    const { top, left } = handleCanvasPopup({
      canvas,
      activeObj: currentGroup.current!,
    });

    setBound({
      top,
      left,
      show: true,
    });

    canvas.renderAll();
    canvas.requestRenderAll();
  };

  const handleCanvasClick = useCallback(
    (e: any) => {
      const canvas = fabricRef.current;
      const isPositive = activeTool?.attributes?.mode === 'positive';
      if (!canvas || isProcessing) return;

      const pointer = canvas.getPointer(e.e);
      addPoint(pointer, isPositive);
    },
    [activeTool?.attributes?.mode, isProcessing, fabricRef]
  );

  const handleClear = useCallback(() => {
    const canvas = fabricRef.current;
    if (!canvas) return;

    canvas.getObjects().forEach((obj: any) => {
      if (
        obj.type === 'mask' ||
        obj.type === 'circle' ||
        obj.objType === 'point'
      ) {
        canvas.remove(obj);
      }
    });

    setPoints([]);
    pathsRef.current = [];
    maskCanvasRef.current?.getContext('2d')?.clearRect(0, 0, 0, 0);

    if (maskCanvasRef.current) {
      maskCanvasRef.current.remove();
      maskCanvasRef.current = null;
    }

    canvas.renderAll();
  }, [fabricRef, maskCanvasRef, pathsRef]);

  const handleCut = () => {
    const canvas = fabricRef.current;
    if (!canvas || points.length === 0 || !maskCanvasRef.current) return;

    const maskContext = maskCanvasRef.current.getContext('2d');
    const [w, h] = [canvas.width!, canvas.height!];

    const maskPixelData = maskContext?.getImageData(0, 0, w, h);

    const image = new Image();

    currentGroup.current?.set('visible', false);
    image.src = fabricRef.current?.toDataURL({ format: 'png' }) as string;

    image.onload = async () => {
      /**
       * TODO: Too much interpolation, need to find a better way to extract the image
       */
      const imageCanvas = new OffscreenCanvas(w, h);
      const imageContext = imageCanvas.getContext('2d');
      imageContext?.drawImage(image, 0, 0, w, h);
      const imagePixelData = imageContext?.getImageData(0, 0, w, h);

      const cutCanvas = new OffscreenCanvas(w, h);
      const cutContext = cutCanvas.getContext('2d');
      const cutPixelData = cutContext?.getImageData(0, 0, w, h);

      for (let i = 3; i < maskPixelData!.data.length; i += 4) {
        if (maskPixelData!.data[i] > 0) {
          for (let j = 0; j < 4; ++j) {
            const offset = i - j;
            cutPixelData!.data[offset] = imagePixelData?.data[offset] || 0;
          }
        }
      }

      cutContext?.putImageData(cutPixelData!, 0, 0);

      const {
        left: minX,
        top: minY,
        width,
        height,
      } = getBoundsFromContext(cutCanvas);

      const finalCanvas = new OffscreenCanvas(width, height);

      const finalCtx = finalCanvas.getContext('2d')!;
      finalCtx.drawImage(
        cutCanvas,
        minX,
        minY,
        width,
        height,
        0,
        0,
        width,
        height
      );

      const dataURL = URL.createObjectURL(await finalCanvas.convertToBlob());

      fabric.Image.fromURL(dataURL, (img) => {
        img.set({
          left: minX,
          top: minY,
          selectable: true,
          cornerColor: 'blue',
          cornerSize: 10,
          transparentCorners: false,
        });
        canvas?.add(img);
        canvas?.bringToFront(img);
        handleClear();
        currentGroup.current?.set('visible', true);
        canvas?.renderAll();
      });
    };
  };

  const getBoundsFromContext = (imageCanvas: any) => {
    let minX = imageCanvas.width;
    let minY = imageCanvas.height;
    let maxX = 0;
    let maxY = 0;
    const ctx = imageCanvas.getContext('2d');

    const boundingData = ctx.getImageData(
      0,
      0,
      imageCanvas.width,
      imageCanvas.height
    ).data;

    for (let y = 0; y < imageCanvas.height; y++) {
      for (let x = 0; x < imageCanvas.width; x++) {
        const alpha = boundingData[(y * imageCanvas.width + x) * 4 + 3];
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    const padding = 5;
    minX = Math.max(0, minX - padding);
    minY = Math.max(0, minY - padding);
    maxX = Math.min(imageCanvas.width, maxX + padding);
    maxY = Math.min(imageCanvas.height, maxY + padding);

    return {
      width: maxX - minX,
      height: maxY - minY,
      left: minX,
      top: minY,
    };
  };
  useEffect(() => {
    const canvas = fabricRef.current;
    const isMagicSelect = activeTool?.value === 'magic-select';

    if (!canvas || !isMagicSelect) return;

    if (canvas) {
      canvas.on('mouse:down', handleCanvasClick);
      canvas.on('contextmenu', (e) => {
        e.e.preventDefault();
        return false;
      });
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:down', handleCanvasClick);
        canvas.off('contextmenu');
      }
    };
  }, [fabricRef, activeTool?.value, handleCanvasClick]);

  useEffect(() => {
    if (!isMagicSelectMode) {
      handleClear();
    }
  }, [isMagicSelectMode, handleClear]);

  return {
    addPoint,
    handleSegmentation,
    handleClear,
    handleCut,
  };
};
