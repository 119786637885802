import { createSmartApi } from '@lib/smart-query';
import queryString from 'query-string';

export const canvasApiCreator = createSmartApi({
  key: 'canvas',
  endpoints: (builder) => ({
    inpaint: builder.mutation({
      key: 'inpaint',
      mutationFn: (data) => {
        return {
          url: `/v2/studio/inpaint`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
    blend: builder.mutation({
      key: 'blend',
      mutationFn: (data) => {
        return {
          url: `/v2/studio/blend`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),

    createCanvas: builder.mutation({
      key: 'create-artboard',
      mutationFn: (data) => ({
        url: '/v2/canvases',
        method: 'POST',
        body: data,
      }),
    }),

    getCanvas: builder.query({
      key: 'get-canvas',
      queryFn: ({ id }) => ({
        url: `/v2/canvases/${id}`,
        method: 'GET',
      }),
    }),

    getCanvases: builder.query({
      key: 'get-canvases',
      isInfinite: true,
      queryFn: ({ search, limit }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/canvases`,
            query: {
              search,
              limit: limit || 20,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    editCanvas: builder.mutation({
      key: 'edit-artboard',
      mutationFn: ({ id, data }) => ({
        url: `/v2/canvases/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesQueries: ['get-canvas'],
    }),

    deleteCanvas: builder.mutation({
      key: 'delete-artboard',
      mutationFn: ({ id }) => ({
        url: `/v2/canvases/${id}`,
        method: 'DELETE',
      }),
    }),

    generateImage: builder.mutation({
      key: 'generate-image',
      mutationFn: ({ data }) => {
        return {
          url: `/v2/compositions`,
          method: 'POST',
          body: data,
        };
      },
    }),

    updateCanvas: builder.mutation({
      key: 'update-artboard',
      mutationFn: ({ id, data }) => {
        return {
          url: `/v2/canvases/${id}`,
          method: 'PUT',
          body: {
            state: data,
          },
        };
      },
    }),

    pollGeneratedImage: builder.query({
      key: 'poll-generated-image',
      queryFn: ({ id }) => {
        return {
          url: `/v2/compositions/${id}`,
        };
      },
    }),

    segment: builder.mutation({
      key: 'segment',
      mutationFn: (data) => {
        return {
          url: `/v2/studio/segment`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
  }),
});

export const {
  useInpaintMutation,
  useBlendMutation,
  useGetCanvasQuery,
  useGenerateImageMutation,
  useEditCanvasMutation,
  usePollGeneratedImageQuery,
  useDeleteCanvasMutation,
  useSegmentMutation,
  useUpdateCanvasMutation,
  useGetCanvasesQuery,
  useCreateCanvasMutation,
  queryKeys: canvasQueryKeys,
} = canvasApiCreator;
