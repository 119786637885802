import { fabric } from 'fabric';

interface ExtendedFabricObject extends fabric.Object {
  id?: string;
  objectId?: string;
  disableFromLayer?: boolean;
  name?: string;
  visible: boolean;
  locked: boolean;
  endAngle?: number;
  radius?: number;
  startAngle?: number;
}

fabric.Object.prototype.toObject = (function (toObject) {
  return function (this: ExtendedFabricObject) {
    return fabric.util.object.extend(toObject.call(this), {
      id: this.id,
      objectId: this.objectId,
      disableFromLayer: this.disableFromLayer,
      name: this.name,
      visible: this.visible,
      locked: this.locked,
      endAngle: this.endAngle,
      radius: this.radius,
      startAngle: this.startAngle,
    });
  };
})(fabric.Object.prototype.toObject);
