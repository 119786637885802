import { useState, useCallback, useEffect } from 'react';

/**
 * A custom hook to manage Fabric.js canvas history (undo/redo functionality)
 * @param {fabric.Canvas} canvas - The Fabric.js canvas instance
 * @returns {Object} History control methods and state
 */
export const useCanvasHistory = ({
  fabricRef,
}: {
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
}) => {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const canvas = fabricRef.current as any;

  const updateHistoryState = useCallback(() => {
    if (canvas) {
      setCanUndo(canvas.canUndo());
      setCanRedo(canvas.canRedo());
    }
  }, [canvas]);

  useEffect(() => {
    if (!canvas) return;

    const handleHistoryChange = () => {
      updateHistoryState();
    };

    canvas.on('history:append', handleHistoryChange);
    canvas.on('history:undo', handleHistoryChange);
    canvas.on('history:redo', handleHistoryChange);
    canvas.on('history:clear', handleHistoryChange);

    updateHistoryState();

    return () => {
      canvas.off('history:append', handleHistoryChange);
      canvas.off('history:undo', handleHistoryChange);
      canvas.off('history:redo', handleHistoryChange);
      canvas.off('history:clear', handleHistoryChange);
    };
  }, [canvas, updateHistoryState]);

  const undo = useCallback(() => {
    if (canvas && canvas.canUndo()) {
      canvas.undo();
    }
  }, [canvas]);

  const redo = useCallback(() => {
    if (canvas && canvas.canRedo()) {
      canvas.redo();
    }
  }, [canvas]);

  const clearHistory = useCallback(() => {
    if (canvas) {
      canvas.clearHistory();
    }
  }, [canvas]);

  const toggleHistory = useCallback(
    (enabled: boolean) => {
      if (!canvas) return;

      if (enabled) {
        canvas.onHistory();
      } else {
        canvas.offHistory();
      }
    },
    [canvas]
  );

  return {
    undo,
    redo,
    saveState: () => canvas?.onHistory(),
    clearHistory,
    toggleHistory,
    canUndo,
    canRedo,
  };
};
