import { ContextMenu } from '@nex/labs';
import { fabric } from 'fabric';

import { handleDelete } from '@/utils/canvas-lib/key-events';
import { bringElement } from '@/utils/canvas-lib/shapes';
import { useFabric } from '@/components/layout';

export const CanvasFrame = ({
  fabricRef,
  canvasRef,
}: {
  fabricRef: React.RefObject<fabric.Canvas>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
}) => {
  const {
    handleClipBoardActions,
    syncShapeInStorage,
    deleteShapeFromStorage,
    containerRef,
  } = useFabric();

  return (
    <main className="h-screen flex justify-center items-center w-full overflow-hidden">
      <ContextMenu.Root>
        <ContextMenu.Trigger>
          <section
            className="bg-[var(--primary-white)] relative box-shadow-[-1px 0px 3px rgba(0, 0, 0, 0.05)] "
            id="canvas"
            ref={containerRef}
          >
            <canvas ref={canvasRef} />
          </section>
        </ContextMenu.Trigger>
        <ContextMenu.Content>
          <ContextMenu.Item
            data-action="bring-to-front"
            onClick={() => {
              bringElement({
                canvas: fabricRef.current as fabric.Canvas,
                direction: 'front',
                syncShapeInStorage,
              });
            }}
          >
            Bring to Front
          </ContextMenu.Item>
          <ContextMenu.Item
            data-action="bring-forward"
            onClick={() => {
              bringElement({
                canvas: fabricRef.current as fabric.Canvas,
                direction: 'back',
                syncShapeInStorage,
              });
            }}
          >
            Send to Back
          </ContextMenu.Item>

          <ContextMenu.Separator />
          <ContextMenu.Item
            data-action="copy"
            shortcut="⌘ C"
            onClick={() => {
              handleClipBoardActions('copy');
            }}
          >
            Copy
          </ContextMenu.Item>

          <ContextMenu.Item
            shortcut="⌘ V"
            data-action="paste"
            onClick={() => {
              handleClipBoardActions('paste');
            }}
          >
            Paste
          </ContextMenu.Item>
          <ContextMenu.Separator />
          <ContextMenu.Item
            shortcut="⌘ ⌫"
            color="red"
            data-action="delete"
            onClick={() => {
              handleDelete(
                fabricRef.current as fabric.Canvas,
                deleteShapeFromStorage
              );
            }}
          >
            Delete
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Root>
    </main>
  );
};
