import React, { useCallback, useRef } from 'react';
import { fabric } from 'fabric';
import _ from 'lodash';

interface LassoProps {
  /**
   * The fabric canvas
   */
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
  /**
   * The active element in the canvas
   * @type {string} value - The active element
   * @type {any} attributes - The attributes of the active element
   */
  activeTool: { value: string; attributes?: any };

  handleToolSelection: (value: { value: string; attributes?: any }) => void;
  activeImageRef?: any | React.MutableRefObject<fabric.Image | null>;
  createInitialMask: () => void;
  pathRef: React.MutableRefObject<any>;
  clearAllSelection: () => void;
}

export const useLasso = ({
  fabricRef,
  activeTool,
  activeImageRef,
  pathRef: path,
  handleToolSelection,
  clearAllSelection,
  createInitialMask,
}: LassoProps) => {
  const points = useRef<number[] | fabric.Point[] | fabric.Point>([]);

  const [isDrawing, setIsDrawing] = React.useState(false);

  const removeLasso = useCallback(() => {
    const canvas = fabricRef.current;
    if (!canvas || !path.current) return;

    const animationFrame = (path.current as any)?.animationFrame;

    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
    }

    clearAllSelection();
    canvas.renderAll();
  }, [fabricRef, path, clearAllSelection]);

  const handleOnMouseDown = useCallback(
    async (options: any) => {
      const canvas = fabricRef.current;
      if (!canvas || activeTool.value !== 'lasso') return;

      if (path.current) {
        try {
          //   await createDisclosure({
          //     message: 'Cancel the current selection to start a new one.',
          //     confirmText: 'Yes',
          //     cancelText: 'No',
          //     backdrop: false,
          //   });

          removeLasso();
        } catch (e) {
          return;
        }
      }
      setIsDrawing(true);

      const lassoStyle = {
        fill: 'transparent',
        stroke: '#2196F3',
        strokeWidth: 2,
        excludeFromExport: true,
        disableFromLayer: true,
        strokeDashArray: [5, 5],
        selectable: false,
      };

      const pointer = canvas.getPointer(options.e);
      points.current = [pointer.x, pointer.y];

      path.current = new fabric.Path(`M ${pointer.x} ${pointer.y}`, {
        ...lassoStyle,
        objectCaching: false,
        hasControls: false,
        hasBorders: false,
        hasRotatingPoint: false,
      });

      canvas.add(path.current);
      canvas.requestRenderAll();
    },
    [fabricRef, activeTool, removeLasso]
  );

  const handleOnMouseMove = useCallback(
    (options: any) => {
      if (activeTool.value !== 'lasso') return;
      const canvas = fabricRef.current;
      if (!canvas || !path.current || !isDrawing) return;

      const pointer = canvas.getPointer(options.e);
      (points.current as any).push(pointer.x, pointer.y);

      path.current.path?.push(['L', pointer.x, pointer.y] as any);
      canvas.renderAll();
    },
    [fabricRef, activeTool?.value, isDrawing]
  );

  const handleOnMouseUp = useCallback(() => {
    setIsDrawing(false);
    const canvas = fabricRef.current;
    if (!canvas || !path.current || activeTool.value !== 'lasso') return;

    path.current.path?.push(['Z'] as any);

    canvas.setActiveObject(path.current);
    canvas.renderAll();

    path.current.set({ fill: 'rgba(255, 255, 255, 0.5)' });

    const startTime = Date.now();
    const duration = 100;
    let animationFrameId: number;

    const animate = () => {
      if (!path.current) {
        cancelAnimationFrame(animationFrameId);
        return;
      }

      if (path.current?.path?.length) {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;

        const offset = (elapsed / duration) * 3;
        path.current.set('strokeDashOffset', offset);
        canvas?.requestRenderAll();

        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    const currentAnimationFrame = animationFrameId;
    (path.current as any).set('animationFrame', currentAnimationFrame);
  }, [fabricRef, createInitialMask, activeTool?.value, path]);

  const cutOutSelection = useCallback(
    async (options?: {
      asNewLayer?: boolean;
      bounds?: { left: number; top: number; width: number; height: number };
    }) => {
      const canvas = fabricRef.current;
      if (!canvas || !path.current) return;

      const clipPath = new fabric.Path(path.current.path, {
        absolutePositioned: true,
        fill: 'transparent',
        stroke: 'transparent',
      });

      path.current.set({ stroke: 'transparent', fill: 'transparent' });

      canvas.renderAll();

      const zoom = canvas.getZoom();
      const clipBounds = clipPath.getBoundingRect();

      const bounds = {
        left: clipBounds.left,
        top: clipBounds.top,
        width: clipBounds.width * zoom,
        height: clipBounds.height * zoom,
      };

      const canvasDataUrl = canvas.toDataURL({
        format: 'png',
        left: bounds.left,
        top: bounds.top,
        width: bounds.width,
        height: bounds.height,
      });

      const maskCanvas = new OffscreenCanvas(bounds.width, bounds.height);
      maskCanvas.width = bounds.width;
      maskCanvas.height = bounds.height;
      const maskCtx = maskCanvas.getContext('2d');

      if (maskCtx) {
        maskCtx.fillStyle = 'black';
        maskCtx.beginPath();
        path.current.path!.forEach(([command, x, y]: any) => {
          if (command === 'M') maskCtx.moveTo(x - bounds.left, y - bounds.top);
          else if (command === 'L')
            maskCtx.lineTo(x - bounds.left, y - bounds.top);
        });
        maskCtx.closePath();
        maskCtx.fill();
        // Let's keep this for the BE
      }

      const outputCanvas = new OffscreenCanvas(bounds.width, bounds.height);
      outputCanvas.width = bounds.width;
      outputCanvas.height = bounds.height;
      const outputCtx = outputCanvas.getContext('2d');

      const originalCanvas = new OffscreenCanvas(bounds.width, bounds.height);
      let scaledHeight = 0;
      let scaledWidth = 0;

      if (activeImageRef.current) {
        scaledWidth =
          activeImageRef.current.width! * activeImageRef.current.scaleX! * zoom;
        scaledHeight =
          activeImageRef.current.height! *
          activeImageRef.current.scaleY! *
          zoom;
        originalCanvas.width = scaledWidth;
        originalCanvas.height = scaledHeight;
      }

      const originalCtx = originalCanvas.getContext('2d');
      const capturedImage = new Image();

      let imageWithHolePromise: Promise<string> | null = null;
      if (!options?.asNewLayer) {
        imageWithHolePromise = new Promise((resolve, reject) => {
          const _element =
            activeImageRef.current &&
            activeImageRef.current.toDataURL({
              format: 'png',
            });

          if (_element) {
            capturedImage.src = _element;
            capturedImage.onload = async () => {
              if (originalCtx && activeImageRef.current) {
                originalCtx.drawImage(
                  capturedImage,
                  activeImageRef.current.left!,
                  activeImageRef.current.top!,
                  scaledWidth,
                  scaledHeight
                );

                originalCtx.globalCompositeOperation = 'destination-out';
                originalCtx.drawImage(maskCanvas, bounds.left, bounds.top);
              }

              const originalWithHoleDataUrl = URL.createObjectURL(
                await originalCanvas.convertToBlob()
              );

              if (activeImageRef.current) {
                fabric.Image.fromURL(originalWithHoleDataUrl, (imgWithHole) => {
                  const el = activeImageRef.current;

                  imgWithHole.set({
                    name: 'image cutout exclude',
                    selectable: true,
                    canPopupAction: false,
                  } as any);

                  // Remove the original image and add the new one with the hole
                  if (activeImageRef.current) {
                    if (activeTool?.attributes?.layer !== 'all') {
                      activeImageRef.current.set({
                        visible: false,
                        evented: false,
                        selectable: false,
                      });

                      canvas.sendToBack(activeImageRef.current);
                    }

                    activeImageRef.current = null;
                  }

                  canvas.add(imgWithHole);
                  canvas.bringToFront(imgWithHole);
                });

                resolve(originalWithHoleDataUrl);
              }
            };
          } else {
            reject('No image found');
          }
        });
      }

      try {
        if (!options?.asNewLayer) {
          await Promise.all([imageWithHolePromise]);
        }
      } catch (e) {
      } finally {
        capturedImage.src = canvasDataUrl;
        capturedImage.onload = async () => {
          if (outputCtx) {
            outputCtx.drawImage(
              capturedImage,
              0,
              0,
              bounds.width,
              bounds.height
            );

            outputCtx.globalCompositeOperation = 'destination-in';
            outputCtx.drawImage(maskCanvas, 0, 0);
          }

          const croppedImageDataUrl = URL.createObjectURL(
            await outputCanvas.convertToBlob()
          );

          fabric.Image.fromURL(croppedImageDataUrl, (croppedImg) => {
            croppedImg.set({
              name: 'image cutout',
              left: bounds.left,
              top: bounds.top,
              selectable: true,
              canPopupAction: false,
            } as any);
            canvas.add(croppedImg);

            canvas.requestRenderAll();

            handleToolSelection({ value: 'select' });
            canvas.setActiveObject(croppedImg);
            removeLasso();
          });
        };
      }
    },
    [
      fabricRef,
      removeLasso,
      handleToolSelection,
      activeImageRef,
      path,
      activeTool?.attributes?.layer,
    ]
  );

  React.useEffect(() => {
    return () => {
      if (path.current) {
        const animationFrame = (path.current as any)?.animationFrame;
        if (animationFrame) {
          cancelAnimationFrame(animationFrame);
        }
      }
    };
  }, [path]);

  return {
    handleOnMouseDown,
    handleOnMouseMove,
    handleOnMouseUp,
    removeLasso,
    cutOutSelection,
  };
};
