import { useCallback, useEffect, useMemo } from 'react';
import { fabric } from 'fabric';

import { handleDelete } from '../key-events';
import { useCopyPaste } from './useCopyFabric';
import { useAppState } from '@/state/store';

export const useKeyEvents = ({
  fabricRef,
  syncShapeInStorage,
  deleteShapeFromStorage,
  redo,
  undo,
}: {
  fabricRef: any;
  syncShapeInStorage: (shape: fabric.Object | string) => void;
  deleteShapeFromStorage: (id: string) => void;
  redo: () => void;
  undo: () => void;
}) => {
  const { copySelectedObjects, pasteObjects } = useCopyPaste(
    fabricRef.current!
  );

  const isUserInteracting = useAppState(
    (state) => state.canvas.isUserInteracting
  );

  const handleClipBoardActions = useCallback(
    (action: string, position?: any) => {
      switch (action) {
        case 'copy':
          copySelectedObjects();
          break;

        case 'paste':
          pasteObjects(position);
          break;

        case 'duplicate':
          const executeCopyPaste = async () => {
            handleClipBoardActions('copy');
            await new Promise((resolve) => setTimeout(resolve, 0)); // Ensures the state update is completed before pasting
            handleClipBoardActions('paste');
          };
          executeCopyPaste();
          break;
      }
    },
    [copySelectedObjects, pasteObjects]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!fabricRef.current) return;

      const canvas = fabricRef.current as fabric.Canvas;

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 67) {
        handleClipBoardActions('copy');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 86) {
        handleClipBoardActions('paste');
      }

      if (e.keyCode === 8 || e.keyCode === 46) {
        const activeElement = document?.activeElement as HTMLElement;
        if (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.tagName === 'SELECT'
        ) {
          return;
        }

        handleDelete(canvas, deleteShapeFromStorage);
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 88) {
        handleClipBoardActions('copy');
        handleDelete(canvas, deleteShapeFromStorage);
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 68) {
        e.preventDefault();
        handleClipBoardActions('duplicate');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.shiftKey && e.keyCode === 90) {
        redo();
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 83) {
        e.preventDefault();
        syncShapeInStorage('save');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 90) {
        if (isUserInteracting) return;
        undo();
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 89) {
        if (isUserInteracting) return;
        redo();
      }

      if (e.keyCode === 191 && !e.shiftKey) {
        e.preventDefault();
      }
    },
    [
      fabricRef,
      handleClipBoardActions,
      deleteShapeFromStorage,
      isUserInteracting,
      syncShapeInStorage,
      undo,
      redo,
    ]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return {
    handleClipBoardActions,
  };
};
