import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';

export const useQueryData = (
  queries: Array<string | object>,
  options: {
    enabled?: boolean;
    isInfinite?: boolean;
    select?: (data: any) => any;
  }
) => {
  const queryClient = useQueryClient();
  const queryData: any = queryClient.getQueryState(
    queries.flat()?.filter(Boolean)
  );

  const data = useMemo(() => {
    if (!options.enabled) return null;

    let data = queryData?.data;

    if (options.isInfinite) {
      data = queryData?.data?.pages?.flat();
    }

    if (options.select) {
      return options.select(data);
    }

    return data;
  }, [queryData, options]);

  const isLoading = useMemo(() => {
    if (options.enabled) {
      return queryData?.status === 'loading';
    }

    return false;
  }, [queryData, options.enabled]);

  return {
    data,
    isLoading,
  };
};
